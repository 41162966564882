<template>
  <home-featured />
  <div class="container">
    <home-about />
    <home-news />
    <home-library />
    <scroll-top />
  </div>
</template>

<script>
import HomeFeatured from '@/components/home/Featured';
import HomeNews from '@/components/home/News';
import HomeAbout from '@/components/home/About';
import HomeLibrary from '@/components/home/Library';
import ScrollTop from '@/components/items/ScrollTop';

export default {
  name: 'Home',
  components: {
    HomeFeatured,
    HomeNews,
    HomeAbout,
    HomeLibrary,
    ScrollTop,
  },
};
</script>
