<template>
  <app-navbar />
  <main>
    <router-view />
  </main>
  <app-footer />
  <cookie-banner />
</template>

<script>
import AppNavbar from '@/components/nav/Navbar';
import AppFooter from '@/components/nav/Footer';
import CookieBanner from '@/components/rgpd/Banner';

export default {
  name: 'App',
  components: {
    AppNavbar,
    AppFooter,
    CookieBanner,
  },
  async created() {
    await this.$store.dispatch('initLogin');
  }
};
</script>
