<template>
  <div class="card" :class="`text-${textAlign}`" @click="$emit('clicked')">
    <div class="card-header">
      <h4 class="card-title"><slot name="title" /></h4>
    </div>
    <div class="card-body"><slot name="body" /></div>
    <div class="card-footer"><slot name="footer" /></div>
  </div>
</template>

<script>
export default {
    name: 'AppCard',
    props: {
      textAlign: {
        type: String,
        default: 'start',
      },
      route: {
        type: String,
        default: '',
      }
    },
    emits: ['clicked'],
};
</script>
