import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "news" */ '../views/News.vue'),
  },
  {
    path: '/library',
    name: 'Library',
    component: () =>
      import(/* webpackChunkName: "library" */ '../views/Library.vue'),
  },
  {
    path: '/login',
    name: 'Auth',
    component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/s/:type',
    name: 'Success',
    component: () =>
      import(/* webpackChunkName: "success" */ '../views/layout/Success.vue'),
  },
  {
    path: '/e/:type',
    name: 'Error',
    component: () =>
      import(/* webpackChunkName: "error" */ '../views/layout/Error.vue'),
  },
  {
    path: '/t/email/:token',
    name: 'EmailToken',
    component: () =>
      import(/* webpackChunkName: "email-token" */ '../views/tokens/Email.vue'),
  },
  {
    path: '/t/password/:token',
    name: 'PasswordToken',
    component: () =>
      import(
        /* webpackChunkName: "password-token" */ '../views/tokens/Password.vue'
      ),
  },
  {
    path: '/u',
    name: 'User',
    component: () =>
      import(/* webpackChunkName: "user" */ '../views/user/Layout.vue'),
    redirect: '/u/dashboard',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'dashboard',
        name: 'UserDashboard',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/user/Dashboard.vue'
          ),
      },
      {
        path: 'profile',
        name: 'UserProfile',
        component: () =>
          import(/* webpackChunkName: "profile" */ '../views/user/Profile.vue'),
      },
      {
        path: 'password',
        name: 'UserPassword',
        component: () =>
          import(
            /* webpackChunkName: "password" */ '../views/user/Password.vue'
          ),
      },
      {
        path: 'activity',
        name: 'UserActivity',
        component: () =>
          import(
            /* webpackChunkName: "activity" */ '../views/user/Activity.vue'
          ),
      },
    ],
  },
  {
    path: '/:catchAll(.*)*',
    redirect: { name: 'Error', params: { type: '404' } },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: 'smooth' };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    next();
    return;
  }
  if (store.state.auth.userLoggedIn) {
    next();
  } else {
    next({ name: 'Home' });
  }
});

export default router;
