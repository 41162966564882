<template>
    <el-input
      v-model="query"
      placeholder="Enter your keyword"
      size="large"
      @input="handleSearch"
      @change="handleSearch"
    >
      <template #append>
        <div class="search-btn">
          <i class="bi bi-search" @click="handleSearch"></i>
        </div>
      </template>
    </el-input>
</template>

<script>
export default {
  name: 'AppSearch',
  emits: ['search'],
  data() {
    return {
      query: '',
    };
  },
  methods: {
    handleSearch() {
      this.$emit('search', this.query);
    },
  },
};
</script>
