<template>
  <div class="row mt-4 mb-4 title-row">
    <div class="col-12">
      <h3 :class="`text-${color}`">{{ title }}</h3>
      <hr :class="`hr-${underlineColor}`" v-if="underline" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppTitle',
  props: {
    title: {
      type: String,
      default: '',
    },
    side: {
      type: String,
      default: 'left',
    },
    underline: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: 'primary',
    },
    underlineColor: {
      type: String,
      default: 'primary',
    },
  },
};
</script>

<style lang="scss">
.title-row {
  h3 {
    font-size: 1.3rem;
    font-weight: 500 !important;
  }
  hr {
    width: 120%;
  }
}
</style>
