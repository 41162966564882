<template>
  <div class="row news-item">
    <div class="col-12 col-lg-6 text-col">
      <h4>{{ item.title }}</h4>
      <p class="text-small mb-4">{{ item.date }}</p>

      <p>{{ item.description }}</p>
      <div class="mt-2 mb-4">
        <a :href="item.link" target="_blank" rel="noopener noreferrer">
          <button class="btn-sm-accent">Read more</button>
        </a>
      </div>
    </div>
    <div class="col-12 col-lg-6 img-col">
      <img class="img-fluid preview-img" :src="item.image" :alt="item.title" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlideItem',
  props: ['item'],
};
</script>
