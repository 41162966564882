<template>
  <header class="header">
    <div class="container-fluid">
      <nav class="navbar-container">
        <router-link :to="{ name: 'Home' }" class="navbar-logo">
          <img
            src="@/assets/img/logo-batters.png"
            class="navbar-brand desktop-brand"
            alt="BATTERS Logo"
          />
          <img src="@/assets/img/logo-batters(mob).png" class="navbar-brand mobile-brand" alt="BATTERS Logo" />
        </router-link>
        <ul
          class="navbar-menu"
          :class="{ active: isActive }"
          @click="isActive = !isActive"
        >
          <li class="navbar-item">
            <router-link :to="{ name: 'Home' }">Home</router-link>
          </li>
          <li class="navbar-item">
            <router-link :to="{ name: 'About' }">About</router-link>
          </li>
          <li class="navbar-item">
            <router-link :to="{ name: 'News' }"> News </router-link>
          </li>
          <li class="navbar-item">
            <router-link :to="{ name: 'Library' }"> Library </router-link>
          </li>
          <li class="navbar-item" v-if="userLoggedIn">
            <router-link :to="{ name: 'User' }">
              <button class="btn-icon"><i class="bi bi-person-fill"></i>My account</button>
            </router-link>
          </li>
          <li class="navbar-item" v-else>
            <router-link :to="{ name: 'Auth' }">
              <button class="btn">Login</button>
            </router-link>
          </li>
        </ul>
        <div
          class="hamburger"
          @click="isActive = !isActive"
          :class="{ active: isActive }"
        >
          <span class="bar"></span>
          <span class="bar"></span>
          <span class="bar"></span>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    userLoggedIn() {
      return this.$store.state.auth.userLoggedIn;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  border-bottom: 1px solid $light-grey;
  padding: 0rem 1.5rem;
}

.desktop-brand {
  width: 10rem;
}

.mobile-brand {
  width: 2rem;
  display: none;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
}

.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 1.25rem;
  height: 3px;
  border-radius: 6px;
  margin: 0.25rem auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: $dark;
}

.navbar-menu {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  align-items: center;
}

.navbar-item {
  margin-left: 2rem;
  font-family: $accent-font;
  font-weight: 500;
  a {
    color: $dark;
  }
  a:hover,
  .router-link-active,
  .router-link-exact-active {
    color: $danger!important;
  }
}

@include bp-down(lg) {
  .desktop-brand {
    display: none;
  }

  .mobile-brand {
    display: block;
  }
  .header {
    padding: 0rem 0.5rem;
  }
  .navbar-menu {
    position: fixed;
    right: -100%;
    top: 3.5rem;
    flex-direction: column;
    background-color: white;
    width: 100%;
    text-align: center;
    transition: 0.3s;
    z-index: 1000;
    border-bottom: 1px solid $light-grey;
    padding-bottom: 2rem;
  }
  .navbar-menu.active {
    right: 0;
  }
  .navbar-item {
    margin: 1.5rem 0;
  }
  .hamburger {
    display: block;
    cursor: pointer;
    margin-top: 1rem;
    margin-right: 0.5rem;
  }
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}
</style>
