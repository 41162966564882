<template>
  <div class="row mt-4">
    <div class="col-12 col-xl-6 text-col">
      <h1 class="text-primary fw-500">Batters</h1>
      <h2 class="text-subtitle mb-4 fw-500">by WeLOOP</h2>
      <p>
        The BATTERS website aims to gather the latest news regarding the growth
        of the e-mobility battery sector. With the contribution of the battery
        sector community, the goal is to keep track of the newest updates at a
        French, European, and international level of the continuously changing
        industry.
      </p>
      <div class="mt-2">
        <router-link :to="{ name: 'About' }"
          ><button class="btn-filled">Learn more</button></router-link
        >
      </div>
    </div>
    <div class="col-12 col-xl-5 img-col">
      <app-image src="batters-info.png" alt="Batters Lifecycle" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeAbout',
};
</script>

<style lang="scss" scoped>
.img-col {
  display: flex;
  justify-content: flex-end;
}

@include bp-down(xl) {
  .img-col {
    justify-content: center;
    max-width: 80%;
    margin: 0 auto;
    margin: 1rem;
  }
}

@include bp-down(md) {
  .text-col {
    text-align: center;
    margin-bottom: 2rem;
  }
}
</style>
