import { ElNotification, ElMessage } from 'element-plus';
import { format, parseISO } from 'date-fns';

export default class Utilities {
  static showMessage(type, message) {
    return ElMessage({
      message,
      type,
      offset: 200,
    });
  }

  static showToast(type, message) {
    return ElNotification({
      message,
      type,
      offset: 200,
    });
  }

  static getISOdate(iso) {
    return iso.toISOString();
  }
  static formatDate(date) {
    const newDate = typeof(date) !== 'string' ? this.getISOdate(date) : date;
    return format(parseISO(newDate), 'dd/MM/yyyy');
  }

  static formatTime(date) {
    const newDate = typeof(date) !== 'string' ? this.getISOdate(date) : date;
    return format(parseISO(newDate), 'HH:mm');
  }
}
