<template>
  <!-- Desktop only -->
  <el-table
    :data="items"
    style="width: 100%"
    :border="true"
    class="desktop-table"
  >
    <el-table-column prop="title" label="Dataset">
      <template #default="scope">
        <div class="">
          <p class="text-primary text-bold break-word">{{ scope.row.title }}</p>
          <p class="break-word">{{ scope.row.description }}</p>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="category"
      label="Data category"
      width="120"
      align="center"
    >
      <template #default="scope">
        {{ getLabel(scope.row.category) }}
      </template>
    </el-table-column>
    <el-table-column
      prop="technology"
      label="Technology"
      width="100"
      align="center"
    >
      <template #default="scope">
        {{ capitalize(scope.row.technology) }}
      </template>
    </el-table-column>
    <el-table-column
      prop="database"
      label="Contact"
      width="150"
      align="center"
    >
      <template #default="scope">
        <a :href="scope.row.link" target="_blank" class="inline-link">{{
          capitalize(scope.row.database)
        }}</a>
      </template>
    </el-table-column>
  </el-table>
  <!-- Desktop only -->
  <el-table
    class="mobile-table"
    :data="items"
    style="width: 100%"
    :border="true"
  >
  <el-table-column prop="title" label="Dataset">
      <template #default="scope">
        <div class="">
          <p class="text-primary text-bold break-word">{{ scope.row.title }}</p>
          <p class="break-word">{{ scope.row.description }}</p>
          <p>Contact: <a :href="scope.row.link" target="_blank" class="inline-link">{{  scope.row.database }}</a></p>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import data_categories from '@/utils/options/data_categories';

export default {
  name: 'LibraryItem',
  props: ['items'],
  data() {
    return {
      categories: data_categories,
    };
  },
  methods: {
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getLabel(value) {
      return this.categories.find((item) => item.value === value).label;
    },
  },
};
</script>

<style lang="scss">
.mobile-table {
  display: none;
}
@include bp-down(lg) {
  .desktop-table {
    display: none;
  }
  .mobile-table {
    display: block;
  }
}
</style>
