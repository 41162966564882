import api from '@/utils/api';

export default class Database {
  static async getAll(collection, params) {
    try {
      const response = await api.get(`/${collection}`, { params: params });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async getById(collection, id, params) {
    try {
      const response = await api.get(`/${collection}/${id}`, {
        params: params,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async create(collection, item) {
    try {
      const response = await api.post(`/${collection}`, item);
      return response.status;
    } catch (err) {
      console.log(err);
    }
  }

  static async update(collection, id, item) {
    try {
      const { status } = await api.patch(`/${collection}/${id}`, item);
      return status;
    } catch (err) {
      console.log(err);
    }
  }

  static async delete(collection, id) {
    try {
      const { status } = await api.delete(`/${collection}/${id}`);
      return status;
    } catch (err) {
      console.log(err);
    }
  }

  static async upload(collection, file) {
    try {
      const formData = new FormData();
      formData.append('file', file, file.name);

      const { data, status } = await api.post(
        `/uploads/${collection}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (status === 200) return data;
    } catch (err) {
      console.log(err);
    }
  }

  static async resetPassword(email) {
    try {
      const { status } = await api.post('/actions', {
        email,
        type: 'RESET_PASSWORD',
      });
      return status;
    } catch (err) {
      console.log(err);
    }
  }

  static async verifyEmail(token) {
    const res = await api.get(`/tokens/${token}`, {
      params: { type: 'VERIFY_EMAIL' },
    });
    return res.status;
  }

  static async register(user) {
    try {
      const { status } = await api.post('/users', user);
      console.log(status)
      return status;
    } catch (err) {
      console.log(err);
    }
  }

  static async updatePassword(old_password, new_password) {
    try {
      const { data, status } = await api.patch(
        '/users/current?type=update_password',
        {
          old_password,
          new_password,
        }
      );

      console.log(data, status);
      return status;
    } catch (err) {
      console.log(err);
    }
  }

  static async updateUser(user) {
    try {
      const { data, status } = await api.patch(
        '/users/current?type=update_user',
        user
      );

      if (status === 200) {
        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(data));
      }

      return status;
    } catch (err) {
      console.log(err);
    }
  }

  static async getActivity(params) {
    try {
      const { status, data } = await api.get('/users/current', { params });
      return status === 200 ? data : [];
    } catch (err) {
      console.log(err);
    }
  }

  /** Password reset from Auth module initially
  // static async resetPassword(token) {
  //   const res = await api.get(`/tokens/${token}`, {
  //     params: { type: 'RESET_PASSWORD' },
  //   });
  //   return res.status;
  // }
  */
}
