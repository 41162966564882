export default [
  {
    label: 'Anode',
    value: 'anode',
  },
  {
    label: 'Battery',
    value: 'battery',
  },
  {
    label: 'Battery cell',
    value: 'battery_cell',
  },
  {
    label: 'Battery pack',
    value: 'battery_pack',
  },
  {
    label: 'Battery management system',
    value: 'battery_management_system',
  },
  {
    label: 'Battery module packaging',
    value: 'battery_module_packaging',
  },
  {
    label: 'Cathode',
    value: 'cathode',
  },
  {
    label: 'Copper collector foil',
    value: 'copper_collector_foil',
  },
  {
    label: 'Electrolyte',
    value: 'electrolyte',
  },
  {
    label: 'Graphite',
    value: 'graphite',
  },
  {
    label: 'Hydroxide',
    value: 'hydroxide',
  },
  {
    label: 'Oxide',
    value: 'oxide',
  },
  {
    label: 'Non-Fe-Co metals',
    value: 'non_fe_co_metals',
  },
  {
    label: 'Recycled content',
    value: 'recycled_content',
  },
  {
    label: 'Recycling',
    value: 'recycling',
  },
  {
    label: 'Separator',
    value: 'separator',
  },
  {
    label: 'Sodium sulfate anhydrite',
    value: 'sodium_sulfate_anhydrite',
  },
  {
    label: 'Used battery',
    value: 'used_battery',
  },
  {
    label: 'Other',
    value: 'other',
  }
];
