import Utilities from '@/utils/services/Utilities';

import api from '@/utils/api';
import jwt_decode from 'jwt-decode';

export default {
  state: {
    userLoggedIn: false,
    user: {}
  },
  mutations: {
    toggleAuth(state) {
      state.userLoggedIn = !state.userLoggedIn;
    },
  },
  actions: {
    async initLogin({ commit, dispatch }) {
      const token = localStorage.getItem('access');
      let isLoggedIn = false;
  
      if (token) {
        const decodedToken = jwt_decode(token);
        const expiration = decodedToken.exp;
        const now = Date.now() / 1000;
        isLoggedIn = expiration > now;
      }
  
      const refresh = localStorage.getItem('refresh');
  
      if (!isLoggedIn && refresh) {
        const status = await dispatch('refreshToken');
        isLoggedIn = status === 200 ? true : false;
      }
  
      if (isLoggedIn) commit('toggleAuth');
      return isLoggedIn;
    },
    async refreshToken() {
      const token = localStorage.getItem('refresh');
  
      if (!token) return;
  
      try {
        const { data, status } = await api.post('/tokens', {
          refresh: token,
        });
  
        if (status === 200) localStorage.setItem('access', data.access);
  
        return status;
      } catch (err) {
        console.log(err);
      }
    },
    async login({ commit }, { email, password, remember_me }) {
      try {
        const { data, status } = await api.post('/sessions', {
          email,
          password,
          remember_me,
        });

        if (status === 200) commit('toggleAuth');
  
        if (data.access) {
          localStorage.setItem('access', data.access);
          localStorage.setItem('refresh', data.refresh);
          localStorage.setItem('user', JSON.stringify(data.user));
        }

        return status;
      } catch (err) {
        return Utilities.showMessage(
          'error',
          'An error occured while trying to log you in. Please check your credentials.'
        );
      }
    },
    async logout({ commit }) {
      const token = localStorage.getItem('refresh');
      try {
        const { status } = await api.delete(`/sessions/${token}`);
  
        if (status === 204) {
          localStorage.removeItem('access');
          localStorage.removeItem('refresh');
          localStorage.removeItem('user');
          commit('toggleAuth');
        }
  
        return status;
      } catch (err) {
        console.log(err);
      }
    },
    getUser() {
      const fromStorage = localStorage.getItem('user');
      return JSON.parse(fromStorage);
    }
  },
};
