<template>
  <i
    class="bi bi-eye-slash cursor-pointer"
    v-if="showPassword"
    @click="toggleShowPassword('hide')"
  />
  <i
    class="bi bi-eye cursor-pointer"
    v-else
    @click="toggleShowPassword('show')"
  />
</template>

<script>
export default {
  props: ['index', 'activated'],
  emits: ['togglePassword'],
  data() {
    return {
      showPassword: false,
      activePassword: null,
    };
  },
  methods: {
    toggleShowPassword(type) {
        this.showPassword = type === 'show' ? true : false;
        this.$emit('togglePassword', type, this.index)
    },
  },
};
</script>
