<template>
  <app-header title="Latest news" />
  <div class="row" v-loading="isLoading">
    <div class="col-12 col-xl-4 mb-2" v-for="item in items">
      <app-card @click="openLink(item)">
        <template #title>{{ item.title }}</template>
        <template #body>
          <div class="home-preview-img mb-4">
            <img class="img-fluid" :src="item.image" :alt="item.title" />
          </div>
          <p>{{ item.description }}</p>
        </template>
        <template #footer
          ><p class="fs-14 mb-0">{{ item.date }}</p></template
        >
      </app-card>
    </div>
  </div>
  <div class="col-12 text-end mt-4">
    <router-link :to="{ name: 'News' }"
      ><button class="btn-filled">See all news</button></router-link
    >
  </div>
</template>

<script>
import Database from '@/utils/services/Database';
export default {
  name: 'HomeNews',
  data() {
    return {
      items: [],
      isLoading: false,
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const { items } = await Database.getAll('news', {
        limit: 3,
        offset: 0,
      });

      this.items = items;
      this.isLoading = false;
    },
    openLink(item) {
      window.open(item.link, '_blank');
    },
  },
};
</script>

<style lang="scss">
.home-preview-img {
  position: relative;
  overflow: hidden;
  height: 200px;
  .img-fluid {
    position: absolute;
    left: -100%;
    right: -100%;
    top: -100%;
    bottom: -100%;
    width: auto;
    height: 200px;
    margin: auto;
  }
}
</style>
