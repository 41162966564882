<template>
  <img
    class="img-fluid"
    :src="require(`@/assets/img/${src}`)"
    :alt="alt"
    @click="showModal = true"
  />
  <el-dialog v-model="showModal" top="5%">
    <img class="img-modal" :src="require(`@/assets/img/${src}`)" :alt="alt" />
  </el-dialog>
</template>

<script>
export default {
  name: 'AppImage',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
};
</script>

<style lang="scss" scoped>
img:hover {
  cursor: pointer;
}
</style>
