<template>
  <app-header title="Latest data submitted" />
  <div class="row" v-loading="isLoading">
    <div class="col-12 mb-2">
      <library-item :items="items" />
    </div>
  </div>
  <div class="col-12 text-end mt-4">
    <router-link :to="{ name: 'Library' }"
      ><button class="btn-filled">See library</button></router-link
    >
  </div>
</template>

<script>
import LibraryItem from '@/components/items/Library';

import Database from '@/utils/services/Database';

export default {
  name: 'HomeLibrary',
  components: {
    LibraryItem,
  },
  data() {
    return {
      items: [],
      isLoading: false,
      page: 1,
      limit: 3,
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const res = await Database.getAll('library', {
        limit: this.limit,
        offset: this.page * this.limit - this.limit,
        filter: this.filter,
      });

      this.items = res.items;
      this.total = res.total;
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-table {
  box-shadow: $shadow-default;
}
</style>
