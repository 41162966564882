import axios from 'axios';

const api = axios.create({
  // baseURL: 'http://localhost:3000/api',
  baseURL: 'https://api.batters.fr/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;